<template>
  <div class="main">
    <h1>404</h1>
  </div>
</template>

<script>
  export default {
    name: "NotFound"
  }
</script>

<style scoped>

</style>
